.container {
  margin-top: 24px;
}

.textRowContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.customNumSteps {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: 2px solid #e75e40;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  margin-inline-end: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #e75e40;
  line-height: 20px;
  letter-spacing: 0.17px;
}

.boldText {
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 700;
  text-align: start;
  line-height: 26px;
  font-style: normal;
}

.normalDescText {
  margin-inline-start: calc(24px + 12px);
  margin-inline-end: 12px;
  color: #373946;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-top: 0;
}

.tickIcon {
  margin-inline-end: 12px;
  margin-inline-start: 0;
}

.rtl .textRowContainer {
  flex-direction: row-reverse;
}
