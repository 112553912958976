@import "@styles/breakpoints.module.scss";

footer.footer {
  background-color: #2a2961;
  color: #fff;
  display: block;
  width: 100%;
  margin-top: 2rem;

  .container {
    padding: 2rem 1rem 0;
    margin: 0 auto;
    position: relative;
  }

  .lists {
    width: 100%;
    display: grid;
    grid-row-gap: 2rem;

    .list {
      h3 {
        color: #e75e40;
        font-size: 0.875rem;
        font-weight: bold;
        margin: 0;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 1rem 0 0;

        li {
          color: #fff;
          font-size: 0.875rem;
          padding-bottom: 0.75rem;
        }

        li:last-child {
          padding-bottom: 0;
        }
      }
    }

    .socialMediaLinks {
      display: flex;

      a {
        margin-right: 1.3rem;
      }

      a:last-of-type {
        margin-right: 0;
      }

      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    .appInstallLinks {
      margin-top: -0.375rem;
      display: flex;
      flex-direction: column;
      row-gap: 0.75rem;

      a {
        height: 69px;
        width: 200px;
        position: relative;
      }

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }

  .divider {
    background-color: #8c8d93;
    height: 1px;
    margin: 1.5rem 0;
  }

  .sole {
    margin-top: 2rem;
    border-top: 1px solid #8c8d93;
    display: flex;
    flex-direction: column;
    padding: 1rem 0 0;
    align-items: center;

    .languageSelector {
      display: flex;
      flex-direction: row;
      border-radius: 4px;
      border: solid 1px #ffffff;
      font-size: 14px;
      margin-top: 0.5rem;
      width: 12rem;
      justify-content: center;
      text-align: center;
      a {
        padding: 0.5rem 1rem;
        width: 50%;
        color: rgba(255, 255, 255, 0.5);

        &:first-of-type {
          border-right: 1px solid #ffffff;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .selected {
        color: #ffffff;
        text-decoration: underline;
      }
    }

    .logoCopyrightContainer {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo {
        line-height: 0;
        margin-bottom: 0.625rem;
      }

      .copyright {
        color: #9b9ca2;
        font-size: 0.875rem;
        padding-bottom: 1.5rem;
        text-align: center;
      }
    }
  }
}

footer.footer.rtl {
  direction: rtl;

  .socialMediaLinks {
    a {
      margin-right: inherit;
      margin-left: 1.5rem;
    }

    a:last-of-type {
      margin-left: 0;
    }
  }

  .sole {
    .languageSelector {
      a {
        &:first-of-type {
          border-right: 0;
          border-left: 1px solid #ffffff;
        }
      }
    }
  }

  .copyright {
    direction: ltr;
  }
}
