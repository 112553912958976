@import "@styles/breakpoints.module.scss";

.container {
  position: relative;
  width: 100%;

  input {
    border-radius: 0.25rem;
    border: 1px solid #dadbe0;
    background-color: #fff;
    caret-color: #e75e40;
    color: #27242c;
    font-size: 0.875rem;
    padding: 0 1rem;
    width: 100%;
    height: 2.25rem;

    outline: none;

    &::placeholder {
      color: #9b9ca2;
    }
  }

  .icon {
    cursor: text;
    fill: #8c8d93;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
  }

  &.open {
    input {
      border: 1px solid #e75e40;
    }
  }

  .searchResultsContainer {
    width: 100%;
    background-color: #fff;
    position: absolute;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .resultHeader {
        height: 2.25rem;
        padding: 0 1rem;
        font-size: 0.75rem;
        color: #9b9ca2;
        display: flex;
        align-items: center;
      }

      .noResults {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #27242c;
        cursor: pointer;
        font-size: 0.875rem;
        border-bottom: none;
        padding: 0 1rem;
        height: 3rem;
        border-bottom: 1px solid #f4f2f7;
        &:hover {
          background-color: #f4f2f7;
        }

        .description {
          color: #e75e40;
          font-weight: bold;
        }
      }

      .noResults {
        color: #9b9ca2;
        cursor: inherit;
      }
    }

    .seeAll {
      padding: 0.25rem 0;

      a {
        color: #e75e40;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;

        &:hover {
          background-color: #f4f2f7;
        }
      }
    }
  }

  &:not(.fullScreen) {
    &.open {
      input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .searchResultsContainer {
      margin-top: -1px;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-top: 1px solid #dadbe0;
      border-bottom: 1px solid #e75e40;
      border-left: 1px solid #e75e40;
      border-right: 1px solid #e75e40;
      box-shadow: 0 10px 40px 0 rgba(55, 57, 70, 0.08);
    }
  }

  &.fullScreen {
    input {
      height: 2.5rem;
    }

    .searchResultsContainer {
      position: fixed;
      left: 0;
      right: 0;
      top: 3.5rem;
      height: calc(100vh - 3.5rem);
    }
  }
}

@media only screen and (min-width: $breakpoint-small + 1) {
  .container {
    .searchResultsContainer {
      .seeAll {
        a {
          height: 2.25rem;
        }
      }
    }
  }
}

.container.rtl {
  direction: rtl;

  .icon {
    right: inherit;
    left: 1rem;
  }
}
