@import "@styles/breakpoints.module.scss";

.placeholder {
  margin-top: 3.5rem;
}

.header {
  box-shadow: 0 10px 40px 0 rgba(55, 57, 70, 0.08);
  background: linear-gradient(99deg, #2a2961, #321985 80%, #321985 80%);
  display: block;
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.5rem;
    // padding: 0 1rem;
    margin: 0 auto;
    position: relative;
  }

  .menuIconContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.75rem;
    flex: 1;
    height: 3.5rem;

    .menuIcon {
      width: 1.5rem;
      height: 1.5rem;
      fill: #ffffff;
    }
  }

  .logoAndSearch {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;

    .logo {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 20px;
      }
    }

    .searchContainer {
      display: none;
      width: 19rem;
    }
  }

  .narrowSearchContainer {
    position: absolute;
    // background-color: #fff;
    width: 100%;
    height: 100%;

    .wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding-left: 1rem;
      box-shadow: 0 10px 40px 0 rgba(55, 57, 70, 0.08);
    }

    .close {
      color: #ffffff;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.75rem;
      width: 3rem;
      height: 3.5rem;
    }
  }

  .headerFunctionsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0.75rem;
    flex: 1;
    height: 3.5rem;

    .notificationBellContainer {
      margin: 0 2rem;
    }

    .searchIcon {
      width: 1.5rem;
      height: 1.5rem;
      fill: #ffffff;
    }
  }
}

.header.rtl {
  direction: rtl;

  .narrowSearchContainer .wrap {
    padding-left: inherit;
    padding-right: 1rem;
  }
}

.welcomeMessageContainer {
  width: 100%;
  padding: 1rem;
  bottom: 0%;
  top: auto;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  .welcomeMessageContent {
    display: grid;
    row-gap: 1rem;
  }

  .welcomeMessageContent.rtl {
    direction: rtl;
  }

  button {
    margin-top: 0.5rem;
  }
}
