.title {
  font-weight: bold;
}
.titleLink {
  color: #e75e40;
}

.titleLink:hover {
  cursor: pointer;
}
