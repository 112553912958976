@import "@styles/breakpoints.module.scss";

footer.footer {
  background-color: #2a2961;
  color: #fff;
  display: block;
  min-width: 48rem;
  width: 100%;
  margin-top: 3rem;

  .container {
    padding: 3rem 0.5rem 0;
    margin: 0 auto;
    max-width: 45rem;
    position: relative;
  }

  .lists {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;

    .list {
      h3 {
        color: #e75e40;
        font-size: 0.875rem;
        font-weight: bold;
        margin: 0;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 1rem 0 0;

        li {
          color: #fff;
          font-size: 0.875rem;
          padding-bottom: 0.75rem;
        }

        li:last-child {
          padding-bottom: 0;
        }
      }
    }

    .socialMediaAndAppLinks {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .socialMediaLinks {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: row;

      a {
        margin-right: 0.75rem;
      }

      a:last-of-type {
        margin-right: 0;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .appInstallLinks {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;

      a {
        height: 46px;
        width: 134px;
        position: relative;
      }

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }

  .divider {
    background-color: #8c8d93;
    height: 1px;
    margin: 1.5rem 0;
  }

  .sole {
    margin-top: 2rem;
    border-top: 1px solid #8c8d93;
    display: flex;
    padding: 1rem 0 0;
    position: relative;

    .languageSelector {
      display: flex;
      flex-direction: row;
      border-radius: 4px;
      border: solid 1px #ffffff;
      font-size: 14px;
      margin-top: 0.5rem;
      position: absolute;
      text-align: center;
      a {
        height: 100%;
        padding: 0.5rem 1rem;

        color: rgba(255, 255, 255, 0.5);

        &:first-of-type {
          border-right: 1px solid #ffffff;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .selected {
        color: #ffffff;
        text-decoration: underline;
      }
    }

    .logoCopyrightContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo {
        line-height: 0;
        margin-bottom: 0.25rem;
      }

      .copyright {
        color: #9b9ca2;
        font-size: 0.875rem;
        padding-bottom: 1.5rem;
        text-align: center;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-small) {
  footer.footer {
    .lists {
      .socialMediaAndAppLinks {
        flex-direction: row;
      }

      .socialMediaLinks {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        a {
          margin-right: 1.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-medium - 1) {
  footer.footer {
    .container {
      padding: 3rem 2rem 0;
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  footer.footer {
    .container {
      max-width: 60rem;
    }

    .divider {
      background-color: #8c8d93;
      height: inherit;
      margin: 0 1.5rem;
      width: 1px;
      display: flex;
    }
  }
}

@media only screen and (min-width: $breakpoint-large) {
  footer.footer .container {
    max-width: 72rem;
  }
}

footer.footer.rtl {
  direction: rtl;

  .socialMediaLinks {
    a {
      margin-right: inherit;
      margin-left: 0.75rem;
    }

    a:last-of-type {
      margin-left: 0;
    }
  }

  .copyright {
    direction: ltr;
  }
  .sole {
    .languageSelector {
      a {
        &:first-of-type {
          border-right: 0;
          border-left: 1px solid #ffffff;
        }
      }
    }
  }
}
