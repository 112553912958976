@import "@styles/breakpoints.module.scss";

.searchResult {
  border-bottom: 1px solid #f4f2f7;
  a {
    display: flex;
    align-items: center;
    color: #27242c;
    cursor: pointer;
    font-size: 0.875rem;
    border-bottom: none;
    padding: 0 1rem;
    height: 3rem;

    svg {
      flex-shrink: 0;
    }

    &:hover {
      background-color: #f4f2f7;
    }
  }

  .textDirection {
    margin: 0 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .description {
    color: #e75e40;
    font-weight: bold;
    margin-left: auto;
    white-space: nowrap;
  }

  &.isRtl {
    .description {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

@media only screen and (min-width: ($breakpoint-small + 1)) {
  .searchResult {
    a {
      height: 2.25rem;
    }
  }
}
