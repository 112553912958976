.container {
  margin: 0 1rem 1rem;
  box-shadow: 0 10px 40px 0 rgba(55, 57, 70, 0.08);
  border-radius: 0.5rem;
  color: #27242c;
}

.profile {
  background-image: linear-gradient(111deg, #2a2961, #321985 80%, #321985 80%);
  border-radius: 0.5rem 0.5rem 0 0;
  color: #fff;
  padding: 1rem;

  .name {
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .heading {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
  }

  .amount {
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
  }

  .amount ~ .heading {
    margin-top: 0.5rem;
  }
}

.difference {
  display: flex;
  align-items: center;
  padding: 0.5rem;

  svg {
    margin-right: 0.5rem;
  }

  span {
    text-decoration: underline;
    font-size: 0.75rem;
  }
}

.difference:hover {
  cursor: pointer;
}
