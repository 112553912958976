@import "@styles/breakpoints.module.scss";
@import "@styles/hideScrollbar.module.scss";

.container {
  flex: 1;
  background: linear-gradient(90deg, #753a88 0%, #cc2b5e 100%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;

  .copySection {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;

    .text {
      color: #fff;
      display: flex;
      flex-direction: column;

      span {
        font-size: 1rem;
        font-weight: bold;
      }

      span:last-child {
        font-size: 0.875rem;
        font-weight: normal;
      }
    }

    .chevron {
      flex-shrink: 0;
      width: 1.5rem;
      fill: #fff;
      transform: rotate(-90deg);
    }
  }

  &.isRtl {
    .copySection {
      .chevron {
        transform: rotate(90deg);
      }
    }
  }
}
