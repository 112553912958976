.container {
  > div ~ div {
    border-top: 0.0625rem solid #dadbe0;
  }

  &.loading {
    > div ~ div {
      border-top: 0.0625rem solid #f4f2f7;
    }
  }

  .image {
    height: 160px;
    width: 160px;
  }

  .emptyNotifications {
    display: flex;
    flex-direction: column;
    align-items: center;

    .msg {
      font-size: 16px;
      color: #373946;
    }
  }
}
