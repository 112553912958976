@import "@styles/breakpoints.module.scss";

.subHeader {
  background-color: #f4f2f7;
  border-bottom: 1px solid #dadbe0;
  box-shadow: 0 10px 40px 0 rgba(55, 57, 70, 0.08);
  min-width: 48rem;
  position: fixed;
  z-index: 4;
  width: 100%;
  padding-top: 3.5rem;
  transition: top 0.2s;
  top: 0;

  &.hide {
    top: -2.75rem;
  }

  .container {
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 45rem;
    height: 2.75rem;
    padding: 0 0.5rem;
  }

  .categoriesContainer {
    cursor: pointer;
    font-size: 0.875rem;
    display: flex;
    height: 100%;
    position: relative;
    outline: none;

    .label {
      display: flex;
      align-items: center;
      font-weight: bold;
      height: 100%;
      .arrowIcon {
        display: block;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #373946;
        transform-origin: center;
        transition: 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        margin-left: 0.5rem;
      }
    }

    .menuContainer {
      position: absolute;
      top: 2.75rem;
      box-shadow: 0 10px 40px 0 rgba(55, 57, 70, 0.08);
      background-color: #ffffff;
      min-width: 272px;
      z-index: 10;
      padding: 0.5rem 0;
      font-size: 16px;
    }

    &.open {
      .label {
        color: #e75e40;
      }

      .arrowIcon {
        transform: rotate3d(0, 0, 1, 180deg);
        border-top: 5px solid #e75e40;
      }
    }
  }

  .divider {
    background-color: #dadbe0;
    width: 1px;
    height: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .navs {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: auto;

    .nav {
      color: #27242c;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      white-space: nowrap;

      &.chromeLink {
        flex-grow: 1;
        justify-content: flex-end;

        a {
          align-items: center;
          display: flex;
          gap: 0.2rem;
        }

        .chromeLogo {
          width: 18px;
          height: 18px;
        }
      }

      &:hover,
      &.selected {
        color: #e75e40;
        font-weight: bold;
      }
    }

    .referAndEarn {
      font-size: 0.75rem;
      color: #e75e40;
      padding: 0.25rem 0.5rem;
      margin: 0.5rem 0;

      background: #ffefec;
      border: 1px solid #e75e40;
      box-sizing: border-box;
      border-radius: 4px;
    }

    > .nav ~ .nav {
      // -=-=-=-=-
      // ask designer the exact spacing between nav.
      margin-left: 1.5rem;
    }
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .subHeader .container {
    max-width: 45rem;
    min-width: 720px;
  }
}

@media only screen and (max-width: $breakpoint-medium - 1) {
  .subHeader .container {
    max-width: 100%;
    min-width: 100%;
    padding: 0 2rem;
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .subHeader {
    padding-top: 3.75rem;

    .container {
      max-width: 60rem;
    }
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .subHeader .container {
    max-width: 72rem;
  }
}

.subHeader.rtl {
  direction: rtl;

  .categoriesContainer {
    .label {
      .arrowIcon {
        margin-right: 0.5rem;
      }
    }
  }

  .divider {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .navs {
    > .nav ~ .nav {
      // -=-=-=-=-
      // ask designer the exact spacing between nav.
      margin-right: 3rem;
      margin-left: inherit;
    }
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .subHeader.rtl {
    direction: rtl;
  }
}
