@import "@styles/breakpoints.module.scss";

.notificationToolTip {
  display: inline;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  text-align: center;
  border-radius: 0.5rem;
  padding: 1.5rem 2rem 2rem 2rem;
  position: absolute;
  z-index: 1;
  top: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 23rem;

  &.empty {
    padding-bottom: 1rem;
  }

  .header {
    font-size: 1rem;
    margin: 0 0 0.75rem 0;
  }

  .body {
    text-align: start;
  }

  .close {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #000;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;

    &.rtl {
      right: auto;
      left: 8px;
    }
  }

  .footer {
    margin-top: 0.75rem;
  }

  .fadeIn {
    opacity: 1;
    animation: fadeIn 0.2s linear;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

// tooltip-triangle
.tooltipTriangle {
  top: 2rem;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  // box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
  width: 0;
  height: 0;
  // adjust triangle shape here
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  border-left: 8px solid transparent;
}
