@import "@styles/breakpoints.module.scss";

.notificationBellContainer {
  position: relative;
  outline: none;

  .notificationBellIcon {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.hide {
      visibility: hidden;
      margin: 0;
      width: 2rem;
    }

    &:focus {
      outline: none !important;
    }
  }
}
