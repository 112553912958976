.container {
  display: flex;
  width: 240px;
  padding: 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #f4f4ff;
  align-self: center;
  cursor: pointer;
  margin-bottom: 16px;
}
.text {
  color: #27242c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.chevron,
.rtlChevron {
  width: 24px;
  height: 24px;
}

.chevron {
  transform: rotate(-90deg);
}

.rtlChevron {
  transform: rotate(90deg);
}
