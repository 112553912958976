@import "@styles/breakpoints.module.scss";

.loading {
  @keyframes linearAnim {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
  @mixin filler {
    border-radius: 4px;
    height: 1rem;
    display: inline-block;
    vertical-align: middle;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 20%,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, 0) 100%
      )
      0px 0px / 100% 100% hwb(253 42% 33%);

    animation: linearAnim 25s infinite linear;
    animation-fill-mode: both;
  }

  .profileMenu {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .boxLarge {
    width: 49px;
    @include filler;
  }
  .boxMedium {
    width: 37px;
    @include filler;
  }
  .boxSmall {
    width: 20px;
    @include filler;
  }
}

.container {
  display: flex;
  position: relative;

  button {
    // TODO: remove this when `<Button />` component becomes rounded globally
    border-radius: 2.25rem;
  }

  .welcomeMenu {
    border-radius: 8px;
    background-color: #ffffff;
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    top: 2.2rem;
    color: #373946;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
    padding: 1.5rem 2rem;
    width: 22.5rem;

    > div:first-of-type {
      font-weight: bold;
      margin-bottom: 4px;
    }

    .closeIconContainer {
      position: absolute;
      right: 4px;
      top: 4px;
      cursor: pointer;
    }
  }

  .authButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    .signInLink {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.375rem;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
    }

    button {
      padding: 0 0.25rem;
    }
  }
}

.menuContainer {
  position: relative;
  outline: none;

  .profileMenu {
    font-size: 14px;
    color: #ffffff;
    border-radius: 36px;
    border: solid 1px #ffffff;
    display: flex;
    align-items: center;
    padding: 6px 12px;
    cursor: pointer;
    height: 36px;
    position: relative;
    white-space: nowrap;

    .profileIconContainer {
      width: 24px;
      height: 24px;
    }

    .walletBalance {
      display: none;
    }

    .arrowIcon {
      // -=-=-=-=-
      // Can move in a shareable style when needed.
      display: block;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #ffffff;
      transform-origin: center;
      transition: 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      margin-left: 0.5rem;
    }

    &.borderless {
      border: none;
    }

    &.active {
      border: solid 1px #ffffff;

      .arrowIcon {
        transform: rotate3d(0, 0, 1, 180deg);
        border-top: 5px solid #ffffff;
      }
    }
  }

  .profileMenu > * {
    margin-right: 0.5rem;
  }

  .profileMenu > *:last-of-type {
    margin-right: 0;
  }

  .dropdownMenu,
  .loggingInMenu {
    border-radius: 8px;
    background-color: #ffffff;
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    top: 2.2rem;
    color: #27242c;

    &.rtl {
      right: auto;
      left: 0;
    }
  }

  .dropdownMenu {
    width: 272px;
    padding: 20px 0;
    box-shadow: 0 10px 40px 0 rgba(55, 57, 70, 0.08);
  }

  .dropdownMenu > a {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    cursor: pointer;
    font-weight: bold;
  }

  .dropdownMenu > a:hover {
    background-color: #f4f2f7;
  }

  a > svg {
    margin-right: 0.75rem;
  }

  .dropdownMenu > a:last-child {
    border-top: 1px solid #f4f2f7;
    margin-top: 0.5rem;
    font-weight: normal;
  }

  .loggingInMenu {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
    padding: 1.5rem 2rem;
    width: 288px;
  }

  .loggingInMenu > div:first-of-type {
    margin-bottom: 4px;
  }
  .loggingInMenu strong {
    color: #e75e40;
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .container {
    display: flex;

    .authButtons {
      display: flex;

      > button ~ button {
        margin-left: 0.5rem;
      }
    }
  }

  .menuContainer {
    .profileMenu {
      .walletBalance {
        display: inline-block;
        min-width: 4rem;
        text-align: center;
      }
    }
  }
}

.container.rtl {
  direction: rtl;
  .menuContainer {
    .profileMenu {
      .arrowIcon {
        // -=-=-=-=-
        // Can move in a shareable style when needed.
        margin-right: 0.5rem;
      }
    }

    a > svg {
      margin-right: inherit;
      margin-left: 0.75rem;
    }

    .profileMenu > * {
      margin-right: inherit;
      margin-left: 8px;
    }

    .profileMenu > *:last-of-type {
      margin-left: 0;
    }
  }

  .welcomeMenu {
    right: inherit;
    left: 0;

    .closeIconContainer {
      left: 4px;
      right: inherit;
    }

    a > svg {
      margin-right: 0rem;
      margin-left: 0.75rem;
    }
  }
}
