@import "@styles/breakpoints.module.scss";

.headContainer {
  // This is for below 1024 screen width
  // combination height of
  // - header
  // - sub-header
  // - sub header bottom border
  height: calc(3.5rem + 2.75rem + 1px);
}

.header {
  box-shadow: 0 10px 40px 0 rgba(55, 57, 70, 0.08);
  background: linear-gradient(94deg, #2a2961, #321985 80%, #321985 80%);
  display: block;
  min-width: 48rem;
  position: fixed;
  z-index: 11;
  width: 100%;

  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 3.5rem;
    padding: 0 0.5rem;
    margin: 0 auto;

    .isFullWidthSearchOpen {
      .logo,
      .languageAndProfile {
        display: none;
      }

      .searchContainer {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;

        .close.isVisible {
          display: flex;
        }
      }
    }
  }

  .languageAndProfile {
    display: flex;
    align-items: center;
  }

  .headerFunctionsContainer {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 0 1.5rem;
  }

  .menuIconContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 3.5rem;
    padding: 0 0.75rem;
    flex: 1;

    .menuIcon {
      width: 1.5rem;
      height: 1.5rem;
      fill: #ffffff;
    }
  }

  .logoAndSearch {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    flex: 1;

    .logo {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 20px;
      }
    }

    .searchContainer {
      display: flex;
      width: 100%;
      min-width: 12rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      position: relative;
      max-width: 17rem;

      .close {
        display: none;
        color: #fff;
        font-size: 1.5rem;
        cursor: pointer;
        width: 2rem;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .header .container {
    max-width: 45rem;
    min-width: 720px;
  }
}

@media only screen and (max-width: $breakpoint-medium - 1) {
  .header .container {
    max-width: 100%;
    min-width: 100%;
    padding: 0 2rem;

    &.isFullWidthSearchOpen {
      .logo,
      .languageAndProfile {
        display: none;
      }

      .searchContainer {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;

        .close.isVisible {
          display: flex;
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .header .container {
    padding: 0 0.5rem;
    max-width: 60rem;
  }

  .headContainer {
    // This is for 1024 and above screen width
    // combination height of
    // - header
    // - sub-header
    // - sub header bottom border
    height: calc(3.75rem + 2.75rem + 1px);
  }

  .header {
    box-shadow: 0 2px 8px 0 rgba(55, 57, 70, 0.08);

    .container {
      height: 3.75rem;
      justify-content: space-between;
    }

    .menuIconContainer,
    .narrowSearchContainer {
      display: none;
    }

    .logoAndSearch {
      .logo img {
        width: 140px;
        height: initial;
      }

      .searchContainer {
        display: block;
        max-width: 24rem;
        height: 2.25rem;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .header .container {
    max-width: 72rem;
  }

  .header .logoAndSearch .searchContainer {
    margin-left: 3.25rem;
    max-width: 29rem;
  }
}

.header.rtl {
  .container {
    direction: rtl;
  }
}

@media only screen and (min-width: $breakpoint-large) {
  .header.rtl .container {
    max-width: 72rem;
  }

  .header.rtl .container,
  .header {
    .logoAndSearch {
      .searchContainer {
        margin-right: 3.25rem;
        max-width: 29rem;
      }
    }
  }
}
