.container {
  border-radius: 8px;
  background-color: #ffffff;

  .heading {
    padding: 0 1.5rem;
    color: #373946;
    font-size: 16px;
    font-weight: bold;
    height: 36px;
    display: flex;
    align-items: center;
  }

  .content {
    max-height: calc(100vh - 9.25rem);
    overflow: auto;

    .item {
      width: 100%;

      .active {
        color: red;
        color: #e75e40;
        border-right: 4px solid #e75e40;
        font-weight: bold;
      }
    }
  }

  .content a {
    cursor: pointer;
    width: 100%;
    display: flex;
    padding: 0.5rem 1.5rem;
  }
  .content a:hover {
    background-color: #f4f2f7;
  }
}

.container.rtl {
  direction: rtl;

  .content {
    .item {
      .active {
        border-right: inherit;
        border-left: 4px solid #e75e40;
      }
    }
  }
}
