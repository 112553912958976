@import "@styles/breakpoints.module.scss";

.container {
  margin: 1.5rem;
}

.header {
  font-size: 1.25rem;
  font-weight: bold;
}

.container > div {
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.subHeader {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.illustrationContainer {
  display: grid;
  grid-template-columns: 1fr 4fr;
  column-gap: 1.5rem;
  row-gap: 1rem;
  font-size: 0.875rem;
}

.illustrationContainer img {
  justify-self: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    font-style: italic;
    color: #e75e40;
  }
}

.subSubHeader {
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.illustrationContainer svg {
  justify-self: center;
}

@media only screen and (max-width: $breakpoint-small - 1) {
  .container .header {
    text-align: center;
    margin-bottom: 3rem;
  }

  .container > div {
    margin-bottom: 2rem;
  }

  .illustrationContainer {
    grid-template-columns: 1fr 2fr;
  }
}

.container.rtl {
  direction: rtl;
  text-align: right;
}
