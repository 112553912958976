@mixin grey-box {
  background: #f4f2f7;
  height: 12px;
  border-radius: 4px;
  color: transparent;
}

.container {
  font-size: 0.875rem;
  padding: 0.75rem 0 1rem;
  background-color: #fff;

  .title {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .content {
    margin-bottom: 0.5rem;
    white-space: pre-wrap;
    line-height: 1rem;
  }

  .date {
    padding-top: 0.5rem;
    line-height: 1.57;
    color: #9b9ca2;
  }

  &.compact {
    font-size: 0.75rem;
    padding: 0.75rem 0 0.5rem;

    .title,
    .content {
      margin-bottom: 0.25rem;
    }
    .date {
      padding-top: 0;
    }
  }

  &.isLoading {
    .title {
      @include grey-box;
      width: 220px;
      margin-bottom: 1rem;
      overflow: hidden;
    }

    .content {
      @include grey-box;
      width: 284px;
      position: relative;
      margin-bottom: 2.5rem;

      &:after {
        position: absolute;
        top: calc(100% + 8px);
        content: "";
        display: block;
        @include grey-box;
        width: 250px;
      }
    }

    .date {
      @include grey-box;
      overflow: hidden;
      width: 90px;
    }
  }
}
