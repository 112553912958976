.languageSelector {
  display: flex;
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1;
  margin-right: -0.25rem;

  .language {
    cursor: pointer;
    padding: 0 0.5rem;
    opacity: 0.75;

    &:hover {
      text-decoration: underline;
    }

    &.selected {
      opacity: 1;
      text-decoration: underline;
      font-weight: bold;
    }
  }

  .language ~ .language {
    border-left: 1px solid #dadbe0;
  }
}

.languageSelector.rtl {
  margin-left: -0.25rem;

  .language ~ .language {
    border-left: 0;
    border-right: 1px solid #dadbe0;
  }
}
