@import "@styles/breakpoints.module.scss";

.modal {
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .container {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15);
    margin: auto;
    text-align: left;
    position: relative;
  }

  .secondaryContainer {
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .closeContainer {
    cursor: pointer;
    z-index: 1000;
    width: 24px;
    height: 24px;
    margin-bottom: 16px;

    .closeIcon {
      fill: #fff;
      width: 24px;
      height: 24px;
    }
  }

  .wrap {
    border-radius: 0.5rem;
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
  }

  .childrenContainer {
    background-color: #fff;
    overflow: auto;
    width: 100%;
    height: 100%;
  }
}

.modal.rtl {
  direction: rtl;
  .container {
    .closeContainer {
      left: 0;
      right: auto;
    }
  }
  &.fullScreen {
    .closeContainer {
      top: 8px;
      left: 16px;
    }
  }
}

@media only screen and (max-width: $breakpoint-small - 1) {
  .modal {
    &.fullScreen {
      .container {
        margin: 0;
      }

      .secondaryContainer {
        display: inline-block;
      }

      .closeContainer {
        top: 0;
        right: 0;
        padding: 1.25rem 1rem 1rem;
        width: auto;
        height: auto;
        position: absolute;
        font-size: 12px;
        color: #9b9ca2;
      }

      .wrap {
        border-radius: 0;
      }

      .childrenContainer {
        height: 100%;
      }
    }
  }
}
