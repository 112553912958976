.container {
  cursor: pointer;

  &.animatedHover {
    &:hover {
      transform: scale(1.05);
    }
  }

  .heart {
    margin: auto;
    display: flex;
    align-items: center;

    &.white {
      svg path {
        fill: none;
        stroke: #ffffff;
        stroke-width: 3;
      }
    }

    &.grey {
      svg path {
        fill: none;
        stroke: #8c8d93;
        stroke-width: 3;
      }
    }

    &.red {
      svg path {
        fill: none;
        stroke: #e75e40;
        stroke-width: 3;
      }
    }

    &.filled {
      svg path {
        fill: #e75e40;
        stroke: #e75e40;
        stroke-width: 3;
      }
    }

    &.clicked {
      animation: animate 0.3s linear;
    }

    @keyframes animate {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.4);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
