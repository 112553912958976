@import "@styles/breakpoints.module.scss";

.drawerContainer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 16rem;
  height: 100%;
  transition-property: transform;
  transition-duration: 250ms;
  transform: translate3d(-100%, 0, 0);
  z-index: 11;

  &.fullScreen {
    width: 100%;
  }

  &.display {
    display: block;
  }

  &.open {
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}

.drawerScrim {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: none;

  &.open {
    display: block;
    z-index: 10;
  }
}

.drawer {
  overflow-y: auto;
  height: 100vh;
  padding-bottom: 6rem;

  .close {
    color: #9b9ca2;
    cursor: pointer;
    font-size: 0.875rem;
    padding: 1rem 0.5rem;

    display: flex;
    align-items: center;

    svg {
      fill: #e75e40;
      height: 1.5rem;
      transform: rotate(90deg);
      margin-right: 0.5rem;
    }
  }
  .authOptions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.25rem 0 1.5rem;
    border-bottom: 1px solid #dadbe0;

    .button {
      padding: 0 1rem;
    }
  }

  .tabs {
    list-style: none;
    margin: 0;
    padding: 0.5rem 0 1rem;
    border-bottom: 1px solid #f4f2f7;

    .tab {
      display: flex;
      align-items: center;
      color: #373946;
      cursor: pointer;
      font-size: 1rem;
      font-weight: bold;
      height: 3rem;
      padding: 0 1rem;

      &:hover {
        background-color: #f4f2f7;
      }

      &.selected {
        color: #e75e40;
        font-weight: bold;
        border-right: 3px solid #e75e40;
      }

      svg {
        margin-right: 0.75rem;
      }
    }
  }

  .languages {
    border-bottom: 1px solid #f4f2f7;
    list-style: none;
    margin: 0;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;

    .language {
      display: flex;
      align-items: center;
      color: #373946;
      cursor: pointer;
      font-size: 1rem;
      height: 3rem;
      padding: 0.5rem 1rem;

      span {
        width: calc(100% - 24px - 24px - 0.75rem);
        margin-left: 2.25rem;
      }

      .checkIcon {
        display: none;
        fill: #e75e40;
      }

      &:hover {
        background-color: #f4f2f7;
      }

      &.selected {
        color: #e75e40;
        font-weight: bold;

        .checkIcon {
          display: block;
        }
      }
    }
  }

  .solo {
    display: flex;
    align-items: center;
    color: #373946;
    cursor: pointer;
    font-size: 1rem;
    height: 3rem;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: #f4f2f7;
    }

    &.selected {
      color: #e75e40;
      font-weight: bold;
      border-right: 3px solid #e75e40;
    }
  }

  .referAndEarn {
    margin: 1rem 1rem 0 1rem;
  }

  .logout {
    border-top: 1px solid #dadbe0;
    margin-top: 0.5rem;
    padding-top: 0.5rem;

    span {
      display: flex;
      align-items: center;
      color: #373946;
      cursor: pointer;
      font-size: 1rem;
      height: 3rem;
      padding: 0.5rem 1rem;

      &:hover {
        background-color: #f4f2f7;
      }
    }
  }
}

.drawerContainer.rtl {
  direction: rtl;
  left: inherit;
  right: 0;
  transform: translate3d(100%, 0, 0);

  &.open {
    transform: translate3d(0, 0, 0);
    left: inherit;
    right: 0;
  }

  .drawer .close svg {
    transform: rotate(-90deg);
    margin-right: inherit;
    margin-left: 0.5rem;
  }

  .tabs {
    .tab {
      &.selected {
        border-right: inherit;
        border-left: 3px solid #e75e40;
      }

      svg {
        margin-right: inherit;
        margin-left: 0.75rem;
      }
    }
  }

  .solo {
    &.selected {
      border-right: inherit;
      border-left: 3px solid #e75e40;
    }
  }

  .languages {
    .language {
      span {
        margin-left: inherit;
        margin-right: 2.25rem;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-medium) {
  .drawerContainer.display {
    display: none;
  }

  .drawerScrim.open {
    display: none;
  }
}
