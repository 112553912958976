@import "@styles/breakpoints.module.scss";

.notificationModal {
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    font-size: 1rem;
    margin: 0 0 0.75rem 0;
    text-align: center;
  }

  .listContainer {
    flex: 1;
    &.empty {
      display: flex;
      flex-direction: column;
      // flex: 1;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  .body {
    position: relative;
  }

  .footer {
    margin-top: 0.5rem;
    width: 100%;
  }

  .fadeIn {
    opacity: 1;
    animation: fadeIn 0.2s linear;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.modal {
  height: 100%;
  width: 100%;
  position: relative;

  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #9b9ca2;

    &.rtl {
      right: auto;
      left: 1rem;
    }
  }
}
