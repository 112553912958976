.container {
  padding: 24px;
}

.header {
  color: #27242c;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.container.rtl {
  direction: rtl;
  text-align: right;
}
.rtl {
  direction: rtl;
  text-align: right;
}
